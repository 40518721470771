jQuery( document ).ready(function($) {
    $('.bio-trigger').click(function (e) { 
        e.preventDefault();
        $('.bio-content').removeClass('active');
        $('.bio-trigger').removeClass('active');
    });

    $('.bio-esp').click(function (e) { 
        e.preventDefault();
        $('.bio-content-esp').addClass('active');
        $(this).addClass('active');
    });

    $('.bio-en').click(function (e) { 
        e.preventDefault();
        $('.bio-content-en').addClass('active');
        $(this).addClass('active');
    });


    $('.subs-popup-close').click(function (e) { 
        e.preventDefault();
        $('.subs-popup').removeClass('active');
    });

    $('.subs-popup-trigger-es').click(function (e) { 
        e.preventDefault();
        $('.subs-popup-es').addClass('active');
    });

    $('.subs-popup-trigger-en').click(function (e) { 
        e.preventDefault();
        $('.subs-popup-en').addClass('active');
    });
});